exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-faq-tsx": () => import("./../../../src/pages/about/faq.tsx" /* webpackChunkName: "component---src-pages-about-faq-tsx" */),
  "component---src-pages-about-information-tsx": () => import("./../../../src/pages/about/information.tsx" /* webpackChunkName: "component---src-pages-about-information-tsx" */),
  "component---src-pages-about-jobs-tsx": () => import("./../../../src/pages/about/jobs.tsx" /* webpackChunkName: "component---src-pages-about-jobs-tsx" */),
  "component---src-pages-about-know-tsx": () => import("./../../../src/pages/about/know.tsx" /* webpackChunkName: "component---src-pages-about-know-tsx" */),
  "component---src-pages-about-policies-tsx": () => import("./../../../src/pages/about/policies.tsx" /* webpackChunkName: "component---src-pages-about-policies-tsx" */),
  "component---src-pages-about-protect-tsx": () => import("./../../../src/pages/about/protect.tsx" /* webpackChunkName: "component---src-pages-about-protect-tsx" */),
  "component---src-pages-about-team-[name]-tsx": () => import("./../../../src/pages/about/team/[name].tsx" /* webpackChunkName: "component---src-pages-about-team-[name]-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-about-testimonials-tsx": () => import("./../../../src/pages/about/testimonials.tsx" /* webpackChunkName: "component---src-pages-about-testimonials-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-announcement-[name]-tsx": () => import("./../../../src/pages/announcement/[name].tsx" /* webpackChunkName: "component---src-pages-announcement-[name]-tsx" */),
  "component---src-pages-announcement-strapi-announcement-slug-tsx": () => import("./../../../src/pages/announcement/{StrapiAnnouncement.slug}.tsx" /* webpackChunkName: "component---src-pages-announcement-strapi-announcement-slug-tsx" */),
  "component---src-pages-announcement-tsx": () => import("./../../../src/pages/announcement.tsx" /* webpackChunkName: "component---src-pages-announcement-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invasive-tsx": () => import("./../../../src/pages/invasive.tsx" /* webpackChunkName: "component---src-pages-invasive-tsx" */),
  "component---src-pages-rentals-truckee-river-tsx": () => import("./../../../src/pages/rentals/truckee-river.tsx" /* webpackChunkName: "component---src-pages-rentals-truckee-river-tsx" */),
  "component---src-pages-rentals-tsx": () => import("./../../../src/pages/rentals.tsx" /* webpackChunkName: "component---src-pages-rentals-tsx" */),
  "component---src-pages-retail-demos-tsx": () => import("./../../../src/pages/retail/demos.tsx" /* webpackChunkName: "component---src-pages-retail-demos-tsx" */),
  "component---src-pages-retail-strapi-sport-slug-tsx": () => import("./../../../src/pages/retail/{StrapiSport.slug}.tsx" /* webpackChunkName: "component---src-pages-retail-strapi-sport-slug-tsx" */),
  "component---src-pages-retail-tsx": () => import("./../../../src/pages/retail.tsx" /* webpackChunkName: "component---src-pages-retail-tsx" */),
  "component---src-pages-tours-lessons-[name]-tsx": () => import("./../../../src/pages/tours-lessons/[name].tsx" /* webpackChunkName: "component---src-pages-tours-lessons-[name]-tsx" */),
  "component---src-pages-tours-lessons-compare-tsx": () => import("./../../../src/pages/tours-lessons/compare.tsx" /* webpackChunkName: "component---src-pages-tours-lessons-compare-tsx" */),
  "component---src-pages-tours-lessons-tsx": () => import("./../../../src/pages/tours-lessons.tsx" /* webpackChunkName: "component---src-pages-tours-lessons-tsx" */),
  "component---src-templates-brands-tsx": () => import("./../../../src/templates/brands.tsx" /* webpackChunkName: "component---src-templates-brands-tsx" */),
  "component---src-templates-crew-tsx": () => import("./../../../src/templates/crew.tsx" /* webpackChunkName: "component---src-templates-crew-tsx" */),
  "component---src-templates-inflatable-tsx": () => import("./../../../src/templates/inflatable.tsx" /* webpackChunkName: "component---src-templates-inflatable-tsx" */),
  "component---src-templates-pedal-tsx": () => import("./../../../src/templates/pedal.tsx" /* webpackChunkName: "component---src-templates-pedal-tsx" */),
  "component---src-templates-retail-tsx": () => import("./../../../src/templates/retail.tsx" /* webpackChunkName: "component---src-templates-retail-tsx" */),
  "component---src-templates-weight-tsx": () => import("./../../../src/templates/weight.tsx" /* webpackChunkName: "component---src-templates-weight-tsx" */),
  "component---src-views-team-view-tsx": () => import("./../../../src/views/team-view.tsx" /* webpackChunkName: "component---src-views-team-view-tsx" */),
  "component---src-views-tour-view-tsx": () => import("./../../../src/views/tour-view.tsx" /* webpackChunkName: "component---src-views-tour-view-tsx" */)
}

